import { zodResolver } from '@hookform/resolvers/zod';
import './Form.css'
import React, { useCallback, useEffect, useState } from "react";
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { z } from "zod";
import { Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FormCustom } from '../../../components/Form';
import { useCompany } from '../../admin/company/hook/useCompany';
import { useGroup } from '../../../hooks/useGroup';
import { useChair } from '../../admin/chair/hook/useChair';
import { useParams } from 'react-router-dom';
import { useRole } from '../../admin/role/hook/useRole';
import { ProfileFormGroup } from '../group/Group';
import { IGetUserInfo } from '../../../interfaces/user/IUser';
import { blobToBase64, clearBase64, getOnlyNumberOfCpfOrCnpj, getOnlyNumberPhone, isValidCnpj, tranformeValideStringDate, validateCPf } from '../../../utils/Helper';
import { showProfile, updateProfile } from '../../../api/profile/profile';
import useAuth from '../../../contexts/Auth';
import { ModalComponent } from '../../../components/ModalComponent';
import { IPhotoSend } from '../../../interfaces/generic/generic.interface';

const MAX_FILE_SIZE = 10 * 1024 * 1024; //10MB
const ACCEPTED_IMAGE_TYPES = ["image/jpeg", "image/jpg", "image/png", "image/webp"];

const profileSchema = z.object({
  name: z.string(),
  email: z.string().email(),
  cpf: z.string()
    .nonempty("Cpf é obrigatório")
    .refine((cpf) => {
      if (cpf.replace(/\D/g, '').length > 11) return isValidCnpj(cpf);
      return validateCPf(cpf);
    }, "precisa ser um cpf|cnpj valido")
    .transform((cpf) => {
      return getOnlyNumberOfCpfOrCnpj(cpf);
    }),
  phone: z.string()
    .nonempty("Telefone é obrigatório")
    .transform((phone: string) => getOnlyNumberPhone(phone)),
  birthDay: z.string(),
  password: z.string(),
  lang: z.string(),
  status: z.string(),
  defaultGroup: z.coerce.number(),
  file_list: z.custom<FileList>()
    .transform((file) => file.length > 0 && file.item(0))
    .refine((file) => !file || (!!file && file.size <= MAX_FILE_SIZE), {
      message: 'O arquivo de ter no maximo 10MB'
    })
    .refine((file) => !file || (!!file && file.type?.startsWith('image')), {
      message: 'Apenas imagem são permitidos'
    }),
  userGroup: z.array(
    z.object({
      id_group: z.coerce.number({ required_error: "Grupo obrigatório" }),
      id_company: z.coerce.number({ required_error: "Compania é obrigatório" }),
      id_chair: z.coerce.number({ required_error: "Cadeira é obrigatório" }),
      id_sub_chair: z.coerce.number().optional(),
      id_role: z.coerce.number({ required_error: "Tipo é obrigatório" }),
    })
  ),
  godparents: z.string().optional(),
  // nexusAt: z.string().optional()
})

export type IProfileFormData = z.infer<typeof profileSchema>

export const ProfileForm: React.FC = () => {
  const { t } = useTranslation()
  const { groupId, userId } = useParams()
  const { companySelect } = useCompany()
  const { infoUser, Logout } = useAuth()
  const { selectGroup, selectGroupDefault } = useGroup()
  const { chairSelect } = useChair()
  const { roleSelect } = useRole()
  const [user, setUser] = useState<IGetUserInfo>({} as IGetUserInfo)
  const [modalUpdateEmail, setModalUpdateEmail] = useState(false)
  const [formValue, setFormValue] = useState<IProfileFormData>({} as IProfileFormData)
  const [photoUrl, setPhotoUrl] = useState('')

  const profileForm = useForm<IProfileFormData>({
    resolver: zodResolver(profileSchema)
  })

  const {
    handleSubmit,
    control,
    setValue,
  } = profileForm

  const { append, fields } = useFieldArray({
    control,
    name: 'userGroup',
  });

  const disabled = userId ? true : false

  const loadForm = (data: IGetUserInfo) => {    
    setValue('cpf', data.cpf)
    setValue('email', data.email)
    setValue('phone', data.phone)
    setValue('lang', data.lang)
    setValue('name', data.name)    
    setValue('status', data.status)    
    setValue('birthDay', data.date_of_birth)
    // setValue('nexusAt', data.nexusAt)
    if (data.godparents) {
      setValue('godparents', data.godparents.name)
    }

    let defaultGroup = 0
    data.userGroups.forEach((item) => {
      if (item.is_default) {
        defaultGroup = item.group_id
      }

      setTimeout(() => {
        append({
          id_group: item.group_id,
          id_company: item.company_id,
          id_chair: item.chair_id,
          id_sub_chair: item.sub_chair_id ? item.sub_chair_id : undefined,
          id_role: item.role_id
        })
      }, 2000);
    })
    setValue('defaultGroup', defaultGroup)
  }

  const fetchUser = async () => {
    const user = await showProfile(+userId!)
    const { data } = user
    setUser(data)
    loadForm(data)
    setPhotoUrl(data.urlPhoto)
  }

  useEffect(() => {
    if (userId) {
      fetchUser()
    }
  }, [])

  const onSubmit = (data: any) => {
    const form: IProfileFormData = data as IProfileFormData
    const updateEmail = isUpdateEmail(form.email)

    if (updateEmail) {
      setModalUpdateEmail(true)
      setFormValue(form)
      return
    }

    fetchUpdateProfile(form)
  }

  const handleActionsModal = (action: boolean) => {
    if (action) {
      fetchUpdateProfile(formValue)
      handleCloseModal()
      return
    }

    handleCloseModal()
  }

  const handleCloseModal = () => {
    setModalUpdateEmail(false)
  }

  const fetchUpdateProfile = async (data: IProfileFormData) => {
    const {
      birthDay,
      cpf,
      defaultGroup,
      email,
      file_list,
      lang,
      name,
      password,
      phone,
    } = data
    const base64photo: IPhotoSend = {} as IPhotoSend;

    if (file_list) {
      setPhotoUrl(URL.createObjectURL(file_list))
      const base64 = await blobToBase64(file_list)
      base64photo.file = clearBase64(base64 as string);
      base64photo.name = file_list.name;
      base64photo.size = file_list.size;
    }

    const { data: response } = await updateProfile({
      id_user: +userId!,
      params: {
        email,
        lang,
        name,
        password,
        phone,
        photo: Object.hasOwn(base64photo, 'file') ? base64photo : undefined,
        birthDay: tranformeValideStringDate(new Date(birthDay)),
        cpf,
        defaultGroup
      }
    })
    
    if (response.data.needLogout) {
      Logout()
      window.location.href = '/'
    }
  }

  const isUpdateEmail = (email: string): boolean => {
    return infoUser.user.email !== email
  }

  return (
    <div>
      <FormProvider {...profileForm}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className='form-profile-container'>
            <div className='form-personal'>
              <div className='form-profile-photo'>
                <FormCustom.Photo
                  field='file_list'
                  url={photoUrl}
                />
              </div>
              <div className='form-profile-user'>
                <Form.Group className='form-profile-user__name'>
                  <Form.Label htmlFor='name'>{t('name')}</Form.Label>
                  <FormCustom.Control showError={true} field='name' />
                </Form.Group>
                <Form.Group className='form-profile-user__email'>
                  <Form.Label htmlFor='email'>{t('email')}</Form.Label>
                  <FormCustom.Control showError={true} field='email' />
                </Form.Group>
                <Form.Group className='form-profile-user__cpf'>
                  <Form.Label htmlFor='cpf'>{t('cpf_cnpj')}</Form.Label>
                  <FormCustom.Documents disabled={disabled} field='cpf' />
                </Form.Group>
                <Form.Group className='form-profile-user__phone'>
                  <Form.Label htmlFor='phone'>{t('phoneLabel')}</Form.Label>
                  <FormCustom.Phone field='phone' />
                </Form.Group>
                <Form.Group className='form-profile-user__birthDay'>
                  <Form.Label htmlFor='birthDay'>{t('dateOfBirth')}</Form.Label>
                  <FormCustom.Control showError={true} type='date' field='birthDay' />
                </Form.Group>
                <Form.Group className='form-profile-user__status'>
                  <Form.Label htmlFor='status'>{t('status')}</Form.Label>
                  <FormCustom.Status disabled field='status' />
                </Form.Group>
                <Form.Group className='form-profile-user__password'>
                  <Form.Label htmlFor='password'>{t('password')}</Form.Label>
                  <FormCustom.Control type='password' field='password' />
                </Form.Group>
                <Form.Group className='form-profile-user__lang'>
                  <Form.Label htmlFor='lang'>{t('lang')}</Form.Label>
                  <FormCustom.Lang field='lang' />
                </Form.Group>
                <Form.Group className='form-profile-user__defaultGroup'>
                  <Form.Label htmlFor='defaultGroup'>{t('groupDefault')}</Form.Label>
                  <FormCustom.Select options={selectGroupDefault} field='defaultGroup' />
                </Form.Group>
                {/* <Form.Group className='form-profile-user__nexusAt'>
                  <Form.Label htmlFor='nexusAt'>{t('nexusAt')}</Form.Label>
                  <FormCustom.Control disabled showError={true} type='date' field='nexusAt' />
                </Form.Group> */}
              </div>
            </div>
            <div className='form-group'>
              {
                fields.map((field, index) => (
                  <ProfileFormGroup
                    chairSelect={chairSelect}
                    companySelect={companySelect}
                    roleSelect={roleSelect}
                    selectGroup={selectGroup}
                    subChairSelect={field.id_sub_chair ? field.id_sub_chair : undefined}
                    key={field.id}
                    index={index}
                    disabled={disabled}
                  />
                ))
              }

            </div>
            <div className='form-footer'>
              <Form.Group className='form-footer__godparents'>
                <Form.Label htmlFor='godparents'>Padrinho</Form.Label>
                <FormCustom.Control
                  field='godparents'
                  disabled={disabled}
                />
              </Form.Group>
            </div>
            <div className='form-atencion'>
              <p><span>ATENÇÃO: </span>
                É possível alterar alguns dados, alterando os mesmos e clicando em salvar, para alterar os demais,
                por favor entre em contato: adm@nexusfn.com.br
              </p>
            </div>
            <div className='form-btn'>
              <Button type='submit'>Salvar</Button>
            </div>
          </div>
        </Form>
      </FormProvider>

      {
        modalUpdateEmail && (
          <ModalComponent.Root
            show={modalUpdateEmail}
            onHide={() => setModalUpdateEmail(false)}
            className='modal-danger-logout'
          >
            <ModalComponent.Content>
              <div>
                Você estar alterando o email, vamos precisar fazer o logout do usuário, deseja continuar com essa ação ?
              </div>
            </ModalComponent.Content>
            <div className='modal-danger-logout__actions'>
              <ModalComponent.Actions
                variant='danger'
                onClick={() => handleActionsModal(true)}
              >
                Sim
              </ModalComponent.Actions>
              <ModalComponent.Actions
                variant='primary'
                onClick={() => handleActionsModal(false)}
              >
                Não
              </ModalComponent.Actions>
            </div>
          </ModalComponent.Root>
        )
      }
    </div>
  )
}